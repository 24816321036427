<template>
  <v-app>
    <app-header
      :headerOption="headerOption"
      ref="appheader"
      style="height : 0px"
      hidden
    ></app-header>
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="6" style="text-align : center;"
              ><img
                class="header_logo"
                src="@/assets/images/logo_default.svg"
                @click="goHome()"
                style="cursor : pointer"
            /></v-col>
            <v-col cols="12" md="6"><h1 style="font-size : 32px"></h1></v-col>
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div class="context flex withSide" style="margin-top : 130px">
        <side-menu
          :sideSet="sideSet"
          @call-pop-in-side-password="callPopWithSidePassword"
          @call-pop-in-side-issue="callPopWithSideIssue"
          @draw-done="initAuthInfo"
          @click-list="listReaded"
          style="margin-top : 55px"
        ></side-menu>
        <div style="width: 80%; margin-top: -50px">
          <div style="height: 50px" />
          <div
            style="width: 100%; text-align: center; font-size: 32px; margin: 10px"
          >
            <span>결제 상품</span>
          </div>
          <div class="row" style="position: relative; left: 10%; width: 1300px">
            <div class="col-md-9 col-sm-9 col-xs-12">
              <v-divider></v-divider>

              <div class="row text-center">
                <div
                  class="col-md-4 col-sm-6 col-xs-12"
                  :key="pro.id"
                  v-for="pro in products"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card class="mx-auto" color="grey lighten-4">
                      <v-img
                        class="white--text align-end"
                        :aspect-ratio="0.707"
                        height="415px"
                        :src="'https://www.drrefer.net/api/pic/' + pro.attchId"
                      >
                        <!--<span
                        style="
                          position: relative;
                          left: -50%;
                          width: 100%;
                          color: #000;
                          font-size: 24px;
                          top: 20px;
                        "
                        >{{ pro.prodNm }}
                      </span>-->
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal display-3"
                            style="
                            height: 415px;
                            position: relative;
                            width: 100%;
                            background-color: #000000;
                            opacity: 0.65;
                            top: 0px;
                          "
                          >
                            <v-btn
                              v-if="hover"
                              v-bind:href="'/v/payment/designDetail/' + pro.id"
                              class=""
                              outlined
                              style="
                              color: #fff;
                              font-size: 18px;
                              font-wright: bold;
                            "
                              >VIEW</v-btn
                            >
                          </div>
                        </v-expand-transition>
                      </v-img>
                      <v-card-text class="text--primary">
                        <div>
                          <a
                            v-bind:href="'/v/payment/designDetail/' + pro.id"
                            style="text-decoration: none"
                            >{{ pro.prodNm }}</a
                          >
                        </div>
                        <div>
                          <v-icon style="margin-right: 5px"
                            >mdi-cash-multiple</v-icon
                          >{{ pro.price }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <app-footer></app-footer> -->
    </div>
  </v-app>
</template>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
</style>

<script>
import AppHeader from "@/components/appHeader/AppHeaderTest";
import AppFooter from "@/components/AppFooter";
import SideMenu from "@/components/sidemenu/SideMenu";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
export default {
  components: { AppHeader, AppFooter, SideMenu },
  data() {
    return {
      sideSet: {
        menuName: "payment",
        sideTitle: "소개 디자인",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      select: "Popularity",
      page: 1,
      products: [],
    };
  },
  created() {
    //this.isLoginActive();
    this.getProdList();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    document.querySelector(".designList").classList.add("active");
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapGetters("prod", ["GET_PROD_LIST"]),
    ...mapGetters("auth", ["GET_IS_LOGIN_ACTIVE"]),
  },
  methods: {
    ...mapActions("prod", ["BRING_PROD_LIST"]),
    ...mapActions("auth", ["IS_LOGIN_ACTIVE"]),
    goHome() {
      this.$router.push("/").catch(()=>{});
    },
    /*async isLoginActive() {
      try {
        await this.IS_LOGIN_ACTIVE();
      } catch (error) {
        console.log(error);
        this.$router.push("/v/login");
      }
    },*/
    async getProdList() {
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: 10,
      };
      try {
        await this.BRING_PROD_LIST(searchOpt).then(() => {
          //console.log(this.GET_PROD_LIST);
          //console.log("이거바", this.GET_PROD_LIST);
          var array = {};
          array = this.GET_PROD_LIST.list;

          for (var i = 0; i < array.length; i++) {
            array[i] = Object.assign({ id: i + 1 }, array[i]);
          }
          // console.log(array[0]);

          this.products = array;
        });
      } catch (error) {
        console.log("error :", error);
      }
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async callPopWithSideIssue(subData) {
      // console.log("sub", subData);
      let pop;
      pop = {
        popType: "IssueChild",
        title: "부계정 관리",
        content:
          "발급할 부계정은 <b style='color:#ff0a00'>1" +
          localStorage.getItem("drId") +
          " </b>입니다.\n변경할 부계정의 비밀번호를 입력해주세요.",
        immediatly: true,
        functionValue: subData,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>
